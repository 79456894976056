/*
 * @Author: donghao donghao.zhao@wwitechs.com
 * @Date: 2022-12-01 16:59:29
 * @LastEditors: meng xiang cheng 3257833780@qq.com
 * @LastEditTime: 2023-09-18 15:44:50
 * @FilePath: \s-share-h5\src\api\init.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Config from '../config'
import Axios from 'axios'

function buildApiBase(type, url, params) {
	// console.log(token)
	let axioUrl
	// if(!host){
	// 	axioUrl = Config.apiUrl + url
	// }else{
		axioUrl = url
	// }
	let token = "",version = "";
	if(params.token){
		token = `Bearer ${params.token}`;
		delete params.token;
	}
	if(params.version){
		version = params.version;
		delete params.version;
	}

	Axios.defaults.withCredentials = true;
	let ret
	if ('get' == type) {
		ret = Axios({
			method: type,
			url: axioUrl,
			params,
      		headers: {
        		version: version,
				Authorization:token
      		},
			paramsSerializer: function(params) {
				return $.param(params, true)
			}

		})
	} else {
		ret = Axios[type](axioUrl, params)
	}
	return ret
}

export async function buildApi(type, url, params, host, version) {
	try {

		let ret = await buildApiBase(type, url, params, host, version)
		return ret.data;
	} catch (e) {}
}
