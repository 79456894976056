/*
 * @Author: donghao donghao.zhao@wwitechs.com
 * @Date: 2022-12-01 16:59:29
 * @LastEditors: meng xiang cheng 3257833780@qq.com
 * @LastEditTime: 2024-01-09 17:01:49
 * @FilePath: \s-share-h5\src\api\report.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {buildApi} from './init'
import Config from '../config'
export default{
	getShareOrder:(params) => {
		console.log(params.token)
		// 
		return buildApi('get',`/api/order/order/share/info/${params.id}`,params,'',params.version,params.token)
	},
	getShareOrderPro:(params)=>{
		return buildApi('get',`/api/order/jobsheet/list/${params.id}`,params,'',params.version,params.token)

	},
	getShareProcess:(params)=>{
		// 
		return buildApi('get',`/api/order/color/progressList/${params.id}`,params,'',params.version,params.token)
	},
    //获取报色单流程卡和颜色卡
	getSalesmanList: (params,version) => buildApi('get', `/api/dyeing/salesman/shareInfo`, params,'',version),
	// /api/dyeing/salesman/shareInfo/v2
	getSalesmanListV2: (params,version) => buildApi('get', `/api/dyeing/salesman/shareInfo/v2`, params,'',version),
	getJobSheetListV2: (params,version) => buildApi('get','/api/dyeing/salesman/jobSheet/listV3',params,'',version),

	// 获取预打打样流程卡和颜色卡
	getProproofing: (params,version) => buildApi('get', `/api/dyeing/salesman/pre/proofing/shareInfo`, params,'',version),
	getProproofingV2: (params,version) => buildApi('get', `/api/dyeing/salesman/pre/proofing/shareInfo/v2`, params,'',version),
	getPreproofPlan: (params,version) => buildApi('get',"/api/dyeing/salesman/pre/proofing/planned",params,'',version)
}