import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [{
	path: '/',
	name: 'layout',
	component: () => import( /* webpackChunkName: "app2" */ '../views/layout/app.vue'),
	children: [{
		path: '/',
		name: 'index',
		component: () => import( /* webpackChunkName: "index" */ '../views/index/index.vue')
	},{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import( /* webpackChunkName: "index" */ '../views/dashboard/index.vue')
	}]
}]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
