/*
 * @Author: donghao donghao.zhao@wwitechs.com
 * @Date: 2022-12-01 16:59:29
 * @LastEditors: donghao donghao.zhao@wwitechs.com
 * @LastEditTime: 2022-12-02 11:26:47
 * @FilePath: /s-share-h5/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from "jquery"
import "./css/index.less";
Vue.config.productionTip = false
import Api from './api/index.js'
import authLogin from "./until/function/authLogin.js"
Vue.prototype.$Api = Api;
Vue.prototype.$AuthLogin = authLogin;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
